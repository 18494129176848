@import '~@increase/typed-components/dist/index.css';
@import '~destyle.css/destyle.css';

html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: Inter, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button {
  font-family: Inter, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}

h1 {
  font-size: 28px;
  line-height: 34px;
}

h2 {
  font-size: 18px;
}

a {
  font-size: 13px;
}

th, td {
  vertical-align: middle;
}

button {
  text-align: center;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.popupbox {
  overflow-y: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  align-items: center;
  max-width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  box-sizing: border-box;
}

.popupbox-wrapper {
  position: absolute;
  top: 0;
  box-sizing: border-box;
  margin: 70px auto;
  overflow: visible;
}
.popupbox-content {
  overflow: visible;
}

.Popover .Popover-tipShape {
  fill: white;
  border: 1px solid #f2f2f2;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}
.Popover .Popover-body {
  border: 1px solid #f2f2f2;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  background-color: white;
}
